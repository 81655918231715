import {AllPorts, OutPortsKeys, PortPayload} from "../../ElmFrameworkPorts";
import {InitConfig, SetUserContextPorts} from "../../Main";

export {
  handleAnalytics,
  sendEvent,
  send,
}


function handleAnalytics<SetUsrCtxPort extends OutPortsKeys>(config: InitConfig<SetUsrCtxPort>, ports: AllPorts): void {
  setUtmTag();
  ports.sendDetailedEvent?.subscribe(sendEvent);
  ports.sendVirtualPageView?.subscribe(sendVirtualPageView);
  if (config.setUserContextPorts) {
    ports[config.setUserContextPorts.setContextOn.portName]?.subscribe((payload: PortPayload<AllPorts[SetUsrCtxPort]>) => sendEvent({
      event: 'userAuthenticated',
      userId: (<SetUserContextPorts<SetUsrCtxPort>>config.setUserContextPorts).setContextOn.toContext(payload).id,
    }));
    ports[config.setUserContextPorts.unsetContextOn]?.subscribe(() => sendEvent({
      event: 'userUnauthenticated',
      userId: undefined
    }));
  }
}


function setUtmTag(): void {
  const utmTag = createUtmTag();
  if (utmTag.utmSource && utmTag.utmCampaign && utmTag.utmMedium) {
    send(utmTag);
  }


  function createUtmTag(): Partial<UtmTag> {
    const queryParams =
      window.location.search
        .slice(1)
        .split('&')
        .map(stringPair => stringPair.split('=').map(decodeURIComponent))
        .reduce((memo, [key, val]) => ({...memo, ...{[key]: val}}), {} as { [k: string]: string });
    return {
      utmSource: queryParams.utm_source,
      utmMedium: queryParams.utm_medium,
      utmCampaign: queryParams.utm_campaign,
      utmTerm: queryParams.utm_term,
      utmContent: queryParams.utm_content,
    };
  }
}


const sendEvent = ({event, eventCategory, eventAction, eventLabel, eventValue, userId}: GtmEvent): void =>
  send({
    event,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    nonInteraction: false,
    userId,
  });

const sendVirtualPageView = (data: { url: string, title: string }): void =>
  send({
    event: <'VirtualPageView'>'VirtualPageView',
    virtualPageURL: data.url,
    virtualPageTitle: data.title,
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    eventValue: undefined,
  });


const send = (data: GtmEvent | VirtualPageView | Partial<UtmTag> | { [k: string]: string }): void =>
  window.gtm?.push(data);


interface GtmEvent {
  event: string
  eventCategory?: string
  eventAction?: string
  eventLabel?: string
  eventValue?: number
  nonInteraction?: boolean
  userId?: string
}

interface VirtualPageView {
  event: 'VirtualPageView'
  virtualPageURL: string
  virtualPageTitle: string
}

interface UtmTag {
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmTerm?: string
  utmContent?: string
}


declare global {
  // @ts-ignore
  interface Window {
    gtm: any
  }
}

