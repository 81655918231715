import * as ErrorReporter from '../ErrorReporter/ErrorReporter';
import {ElmFrameworkPorts} from '../../ElmFrameworkPorts';
import {ServiceWorkerPostMsg} from '../../../sw';
import {alwaysVoid} from '../../Lib/util';
import {webPush} from '../../synchronised-async-services';

export function handlePushNotifications(isHybrid: boolean, isIos: boolean, appPorts: ElmFrameworkPorts): void {
  clearOutNotificationsOnAppStart(isHybrid);
  isHybrid ?
    handleHybridNotifications(isIos, appPorts) :
    handleWebNotifications(appPorts);
}

function clearOutNotificationsOnAppStart(isHybrid: boolean): void {
  clearNotifs();
  document.addEventListener('resume', clearNotifs, false);

  function clearNotifs() {
    if (isHybrid) {
      (window.FirebasePlugin.clearAllNotifications && window.FirebasePlugin.clearAllNotifications());
    } else {

    }
  }
}


function handleHybridNotifications(isIos: boolean, appPorts: ElmFrameworkPorts) {
  isIos ?
    initIosPush(appPorts) :
    enableHybridNotifications(appPorts);
  appPorts.enablePushNotifications?.subscribe(() => enableHybridNotifications(appPorts));
  appPorts.setNotificationBadgeCount?.subscribe(count => window.FirebasePlugin.setBadgeNumber(count));
}


function handleWebNotifications(appPorts: ElmFrameworkPorts) {
  listenNotifications(appPorts);
  webPush.getPushPermissionsStatus()
    .then(status => appPorts.receivePushStatus.send(status))
    .catch(handleErr);

  webPush.onTokenRefresh(status => appPorts.receivePushStatus.send(status));

  appPorts.enablePushNotifications?.subscribe(() =>
    webPush.enableWebNotifications()
      .then(pushStatus => appPorts.receivePushStatus.send(pushStatus))
      .catch(handleErr));
}

function listenNotifications(appPorts: ElmFrameworkPorts) {
  if (!navigator.serviceWorker) {return}
  navigator.serviceWorker.addEventListener('message', (msg: { data: ServiceWorkerPostMsg }) => {
    if (msg.data.kind === 'pushNotification') {
      appPorts.receivePushMsg.send(msg.data);
    }
  });
}


function initIosPush(appPorts: ElmFrameworkPorts) {
  const {isRemoteNotificationsEnabled, isRegisteredForRemoteNotifications} = (<any>window.cordova.plugins).diagnostic;
  isRemoteNotificationsEnabled((enabled: any) =>
    enabled ?
      enableHybridNotifications(appPorts) :
      isRegisteredForRemoteNotifications((registered: any) =>
        registered ?
          appPorts.receivePushStatus.send(['Disabled', null, null]) :
          appPorts.receivePushStatus.send(['Unregistered', null, null]), handleErr), handleErr);
}

function enableHybridNotifications(appPorts: ElmFrameworkPorts): void {
  grantHybridPermissions()
    .then(() => window.FirebasePlugin.grantPermission(alwaysVoid, alwaysVoid)) // more like init
    .then(() => getFCMToken())
    .then(token => appPorts.receivePushStatus.send(['Enabled', token, true]))
    .then(() => listenHybridNotifications(appPorts))
    .catch(handleErr);
}

function listenHybridNotifications(appPorts: ElmFrameworkPorts): void {
  window.FirebasePlugin.onMessageReceived(
    data => {
      // console.log('kromid notification', data);
      appPorts.receivePushMsg.send({
        receivingCircumstances: data.messageType == 'data' ? 'background' : data.tap == 'background' ? 'tapped' : 'background',
        data: {
          notification: {
            title: (<any>data).aps ? (<FirebaseNotifIos>data).aps.alert.title : ((<FirebaseNotifAndroid>data).title || ''),
            body: (<any>data).aps ? (<FirebaseNotifIos>data).aps.alert.body : ((<FirebaseNotifAndroid>data).body || ''),
          },
          data: {payload: data.payload}
        }
      });
    },
    err => handleErr('listenHybridNotifications: ' + err)
  );
}


function grantHybridPermissions(): Promise<void> {
  return new Promise((resolve, reject) =>
    (<any>window.cordova.plugins).notification.local.requestPermission((isGranted: any) => isGranted ? resolve() : reject('push permission rejected')));
}

function getFCMToken(): Promise<string> {
  return new Promise((resolve, reject) =>
    window.FirebasePlugin.onTokenRefresh(resolve, reject));
}

function handleErr(err: string) {
  ErrorReporter.captureMessage('Push notifications: ' + err);
}


declare global {
  interface Window {
    FirebasePlugin: FirebasePlugin,
    cordova: Cordova
  }
}


interface FirebasePlugin {
  getToken(success: (value: string) => void, error: (err: string) => void): void

  onTokenRefresh(success: (value: string) => void, error: (err: string) => void): void

  getAPNSToken(success: (value: string) => void, error: (err: string) => void): void

  onMessageReceived(success: (value: Notification) => void, error: (err: string) => void): void

  grantPermission(success: (value: boolean) => void, error: (err: string) => void): void

  hasPermission(success: (value: boolean) => void, error: (err: string) => void): void

  unregister(): void

  setBadgeNumber(badgeNumber: number): void

  getBadgeNumber(success: (badgeNumber: number) => void, error: (err: string) => void): void

  clearAllNotifications(): void

  subscribe(topic: string): void

  unsubscribe(topic: string): void

  createChannel(channel: IChannelOptions, success: () => void, error: (err: string) => void): void

  setDefaultChannel(channel: IChannelOptions, success: () => void, error: (err: string) => void): void

  deleteChannel(channel: string, success: () => void, error: (err: string) => void): void

  listChannels(success: (list: { id: string; name: string }[]) => void, error: (err: string) => void): void

  setAnalyticsCollectionEnabled(setEnabled: boolean): void

  logEvent(eventName: string, eventProperties: object): void

  setScreenName(screenName: string): void

  setUserId(userId: string): void

  setUserProperty(userName: string, userValue: string): void

  setCrashlyticsCollectionEnabled(): void

  setCrashlyticsUserId(userId: string): void

  sendCrash(): void

  logMessage(message: string): void

  logError(errorMessage: string): void

  verifyPhoneNumber(
    phoneNumber: string,
    timeOutDuration: number,
    success: (value: string | object) => void,
    error: (err: string) => void
  ): void

  fetch(cacheExpirationSeconds: number, success: () => void, error: (err: string) => void): void

  fetch(success: () => void, error: (err: string) => void): void

  activateFetched(success: (activated: boolean) => void, error: (err: string) => void): void

  getValue(key: string, success: (value: string) => void, error: (err: string) => void): void

  getByteArray(key: string, success: (value: object) => void, error: (err: string) => void): void

  getInfo(success: (info: object) => void, error: (err: string) => void): void

  setConfigSettings(configSettings: object, success: (info: object) => void, error: (err: string) => void): void

  setDefaults(defaultSettings: object, success: (info: object) => void, error: (err: string) => void): void

  setPerformanceCollectionEnabled(setEnabled: boolean): void

  startTrace(name: string, success: () => void, error: (err: string) => void): void

  incrementCounter(name: string, counterName: string, success: () => void, error: (err: string) => void): void

  stopTrace(name: string): void
}


type Notification
  = FirebaseNotifIos
  | FirebaseNotifAndroid

type FirebaseNotifIos = MessageType & {
  aps: { alert: { title: string, body: string } }
  payload: string
}

type FirebaseNotifAndroid = MessageType & {
  title?: string
  body?: string
  payload: string
}

type MessageType
  = { messageType: 'data' }
  | { messageType: 'notification', tap: 'background' | 'foreground' };


interface IChannelOptions {
  id: string
  name?: string
  sound?: string
  vibration?: boolean | number[]
  light?: boolean
  lightColor?: string
  importance?: 0 | 1 | 2 | 3 | 4
  badge?: boolean
  visibility?: -1 | 0 | 1
}