import {ElmFrameworkPorts, PortOut} from "../../ElmFrameworkPorts";
import {captureException} from "../ErrorReporter/ErrorReporter";
import {toFacebookSdk} from "./Facebook";
import {toGoogleAuthSdk} from "./Google";
import {once} from "../../Lib/util";
import StatusResponse = facebook.StatusResponse;
import GoogleAuth = gapi.auth2.GoogleAuth;

// const {userPoolName, region, clientId, socialProviderRedirectUrl} = process.env.config.cognito;

export {
  handleSocialAuth,
  GoogleAuthSuccess,
  FacebookAuthSuccess,
}


function handleSocialAuth(isHybrid: boolean, appPorts: ElmFrameworkPorts, unauthPortKey?: keyof ElmFrameworkPorts): void {
  appPorts.loadSocialLibraries?.subscribe(once(() => {
    checkExistingSocialSessions(isHybrid, appPorts);
    handleSocialAuthTaps(appPorts, createAuthHandlers(isHybrid, appPorts));
    if (unauthPortKey) {
      handleUnauth(isHybrid, appPorts[unauthPortKey] as PortOut);
    }
  }));
}

function handleSocialAuthTaps(appPorts: ElmFrameworkPorts, authHandlers: Promise<AuthHandlers>): void {
  authHandlers.then(handlers => {
    appPorts.googleSdkLoaded.send(null);
    appPorts.facebookSdkLoaded.send(null);
    appPorts.facebookLogin?.subscribe(handlers.facebook);
    appPorts.googleLogin?.subscribe(handlers.google);
  });
}


const handleUnauth = (isHybrid: boolean, unauthPort: PortOut): void => {
  unauthPort?.subscribe(() => {
    if (isHybrid) {
      //todo: implement
    } else {
      toWebSocialSdks()
        .then(([fbSdk, googleSdk]) => {
          fbSdk?.logout();
          googleSdk?.signOut();
        });
    }
  });
};


const checkExistingSocialSessions = (isHybrid: boolean, appPorts: ElmFrameworkPorts): void =>
  isHybrid ?
    checkHybridSocialSessions(appPorts) :
    checkWebSocialSessions(appPorts);

function checkHybridSocialSessions(appPorts: ElmFrameworkPorts): void {
  getHybridFbLoginStatus()
    .then(res => appPorts.receiveFacebookAuth.send(res))
    .catch(() =>
      tryHybridSilentLoginWithGoogle()
        .then(res => appPorts.receiveGoogleAuth.send(res)));
}

function checkWebSocialSessions(appPorts: ElmFrameworkPorts): void {
  toWebSocialSdks()
    .then(([fbSdk, googleSdk]) =>
      fbSdk &&
      getWebFbLoginStatus(fbSdk)
        .then(res => appPorts.receiveFacebookAuth.send(res))
        .catch(() =>
          Promise.reject('google web auth does not yet support auth check')
            .then(res => appPorts.receiveGoogleAuth.send(res))));
}


const createAuthHandlers = (isHybrid: boolean, appPorts: ElmFrameworkPorts): Promise<AuthHandlers> =>
  isHybrid ?
    createHybridHandlers(appPorts) :
    createWebHandlers(appPorts);


const toWebSocialSdks = (): Promise<[fb.FacebookStatic | undefined, GoogleAuth | undefined]> =>
  Promise.all([
    process.env.config.facebookId ? toFacebookSdk(process.env.config.facebookId) : undefined,
    process.env.config.googleClientId ? toGoogleAuthSdk(process.env.config.googleClientId) : undefined,
  ]);

const createWebHandlers = (appPorts: ElmFrameworkPorts): Promise<AuthHandlers> =>
  toWebSocialSdks()
    .then(([facebookSdk, googleAuthSdk]) => ({
      facebook: () => console.error('facebook login not implemented'),
      google: () => googleAuthSdk && webAuthWithGoogle(googleAuthSdk, appPorts),
    }));

function webAuthWithGoogle(googleAuthSdk: GoogleAuth, appPorts: ElmFrameworkPorts): void {
  appPorts.socialWindowOpened.send(null);
  googleAuthSdk.grantOfflineAccess()
    .then(({code}) => appPorts.receiveGoogleAuth.send({serverAuthCode: code}))
    .catch(err => {
      appPorts.socialWindowClosed.send(null);
      captureException(err); // removed second parameter {extra: err}. Add if sentry is re-enabled
    });
}


function getWebFbLoginStatus(fbSdk: fb.FacebookStatic): Promise<StatusResponse> {
  return new Promise((resolve, reject) => {
    fbSdk.getLoginStatus((res: StatusResponse) => {
      if (res.status == 'connected') {
        resolve(res);
      } else {
        reject(res);
      }
    })
  });
}

//////////////////////////////////////////////////////////////////////////////////////////


const createHybridHandlers = (appPorts: ElmFrameworkPorts): Promise<AuthHandlers> =>
  Promise.resolve({
    facebook: () => nativeAuthFacebook(appPorts),
    google: () => nativeAuthGoogle(appPorts),
  });

function nativeAuthFacebook(appPorts: ElmFrameworkPorts): void {
  appPorts.socialWindowOpened.send(null);
  getHybridFbLoginStatus()
    .catch(() => loginWithFb())
    .then(res => appPorts.receiveFacebookAuth.send(res))
    .catch(err => {
      appPorts.socialWindowClosed.send(null);
      captureException(err);
    });


  function loginWithFb(): Promise<FacebookAuthSuccess> {
    return new Promise((resolve, reject) => {
      window.facebookConnectPlugin.login(
        ['public_profile', 'email', 'user_gender'],
        res => resolve(res),
        err => reject(err)
      );
    });
  }
}

function getHybridFbLoginStatus(): Promise<FacebookAuthSuccess> {
  return new Promise((resolve, reject) => {
    window.facebookConnectPlugin.getLoginStatus(
      res => res.status == 'connected' && res.authResponse.accessToken ? resolve(res) : reject(res),
      err => reject(err)
    );
  });
}

function nativeAuthGoogle(appPorts: ElmFrameworkPorts): void {
  appPorts.socialWindowOpened.send(null);
  tryHybridSilentLoginWithGoogle()
    .catch(() => loginWithGoogle())
    .then(res => appPorts.receiveGoogleAuth.send(res))
    .catch(err => {
      console.log('nativeAuthGoogle err:', err);
      appPorts.socialWindowClosed.send(null);
      captureException(err);
    });

  function loginWithGoogle(): Promise<GoogleAuthSuccess> {
    return new Promise((resolve, reject) => {
      (<any>window.plugins).googleplus.login( // https://github.com/EddyVerbruggen/cordova-plugin-googleplus
        {offline: true, webClientId: process.env.config.googleClientId},
        //1067277998983-5rsf9o1t69lnsisn0i47o1fco4jlbfp1.apps.googleusercontent.com
        (res: GoogleAuthSuccess) => resolve(res),
        (err: string) => reject(err)
      );
    });
  }

}

function tryHybridSilentLoginWithGoogle(): Promise<GoogleAuthSuccess> {
  return new Promise((resolve, reject) => {
    (<any>window.plugins).googleplus.trySilentLogin(
      {offline: true, webClientId: process.env.config.googleClientId},
      (res: GoogleAuthSuccess) => res.serverAuthCode ? resolve(res) : reject('no server auth code'),
      (err: string) => reject(err)
    );
  });
}

interface AuthHandlers {
  facebook: () => void
  google: () => void
}


// function socialAuthWindow(isHybrid: boolean, provider: SocialAuthProvider, appPorts: ElmFrameworkPorts): void {
//   appPorts.socialWindowOpened.send(null);
//   openSocialAuthWindow(isHybrid, provider)
//     .then(justDo((session: CognitoSession) => appPorts.receiveUserAuthSession.send(session)))
//     .then(() => appPorts.socialWindowClosed.send(null))
//     .catch(() => appPorts.socialWindowClosed.send(null));
// }

// function openSocialAuthWindow(isHybrid: boolean, provider: SocialAuthProvider): Promise<CognitoSession> {
//   return new Promise((resolve, reject) => {
//     const currAuthUrl = `https://${userPoolName}.auth.${region}.amazoncognito.com/oauth2/authorize?identity_provider=${provider}&redirect_uri=${encodeURIComponent(socialProviderRedirectUrl)}&response_type=CODE&client_id=${clientId}`;
//     const theWindow = openSocialAuthWindowForProvider(isHybrid, provider, currAuthUrl);
//     if (isHybrid) {
//       listenForSessionInAppBrowser(theWindow, currAuthUrl).then(resolve, reject);
//     } else {
//       listenForSession(theWindow, currAuthUrl).then(resolve, reject);
//     }
//   });
// }

// function listenForSessionInAppBrowser(theWindow, url: string): Promise<CognitoSession> {
//   return new Promise((resolve, reject) => {
//     theWindow.addEventListener('loadstop', () =>
//       theWindow.executeScript(
//         {code: 'window.msg'},
//         ([msg]) => {
//           if (isSession(msg)) {
//             resolve(toCognitoSession(msg));
//             theWindow.close();
//           } else if (msg && msg.error === 'cognitoSocialAuthBug') {
//             theWindow.executeScript({code: `window.location.href = '${url}'`}, identity);
//           } else if (msg && msg.error) {
//             theWindow.close();
//             captureException(msg);
//           }
//         }));
//     theWindow.addEventListener('exit', () => reject());
//   });
// }


// function listenForSession(theWindow: Window, url: string): Promise<CognitoSession> {
//   return new Promise((resolve, reject) => {
//     window.addEventListener('message', sessionListener);
//     setIntervalWrapper(interval => {
//       if (!!theWindow && theWindow.closed) {
//         window.removeEventListener('message', sessionListener);
//         reject();
//         clearInterval(interval);
//       }
//     }, 500);
//
//     function sessionListener(msg) {
//       if (msg.data && msg.data.token_type === 'Bearer') {
//         resolve(toCognitoSession(msg.data));
//       } else if (msg.data && msg.data.error === 'cognitoSocialAuthBug') {
//         theWindow.location.href = url;
//       } else {
//         console.info('listenForSession: irrelevant message: ', msg);
//       }
//     }
//   });
// }


// function openSocialAuthWindowForProvider(isHybrid: boolean, provider: SocialAuthProvider, url: string): Window {
//   return openWindow(isHybrid, {
//     url: url,
//     title: `Login with ${provider}`,
//     features: "location=no",
//     provider
//   });
// }

// function isSession(data: any): boolean {
//   return data && data.access_token && data.id_token && data.refresh_token;
// }

// function toCognitoSession(data: { access_token: string, id_token: string, refresh_token: string }): CognitoSession {
//   return {
//     accessToken: data.access_token,
//     idToken: data.id_token,
//     refreshToken: data.refresh_token,
//   };
// }
// type SocialAuthProvider = 'Facebook' | 'Google'


interface GoogleAuthSuccess {
  // accessToken: string
  // displayName: string
  // email: string
  // familyName: string
  // givenName: string
  // idToken: string
  // imageUrl: string
  // refreshToken: string
  serverAuthCode: string
  // userId: string
}

interface FacebookAuthSuccess {
  authResponse: {
    accessToken: string
    userID: string
  },
  status: 'connected' | string
}


declare global {
  interface Window {
    facebookConnectPlugin: {
      login: (
        permissions: string[],
        successCb: (res: FacebookAuthSuccess) => void,
        errCb: (err: any) => void
      ) => void
      getLoginStatus: (
        successCb: (res: FacebookAuthSuccess) => void,
        errCb: (err: any) => void
      ) => void
    }
  }
}

