import {AppPorts} from './elm-ports';
// import * as Analytics from './ElmFramework/Feature/Analytics/Analytics';

export {
  handleAnalytics,
  InterestInGuyEvent,
};


function handleAnalytics(ports: AppPorts): void {
  // ports.startChatWithUserId.subscribe(sendInterestInGuyEvent('chatWithGuyStarted'));
}



interface InterestInGuyEvent {
  skills: string[]
  skillCategories: string[]
  country: string
  budget?: { currency: string, amount: number }
}

// const sendInterestInGuyEvent =
//   (eventName: string) =>
//     (payload: InterestInGuyEvent): void =>
//       Analytics.send({
//         event: eventName,
//         eventCategory: eventName,
//         eventAction: eventName,
//         eventLabel: payload.skills.join(', '),
//         eventValue: payload.budget && payload.budget.amount,
//         skills: payload.skills,
//         skillCategories: payload.skillCategories,
//         country: payload.country,
//         budgetCurrency: payload.budget && payload.budget.currency,
//         budgetAmount: payload.budget && payload.budget.amount,
//         nonInteraction: false,
//       });






