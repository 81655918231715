import { ElmFrameworkPorts } from '../../ElmFrameworkPorts';
import { confirmCardSetup } from './stripe-service';

export {
  handleStripePorts,
}

function handleStripePorts(ports: ElmFrameworkPorts): void {
  ports.confirmStripeCardSetup?.subscribe(cmd =>
    confirmCardSetup(cmd)
      .then(() => ports.receiveStripeConfirmCardSetupSuccess?.send(null))
      .catch(err => ports.receiveStripeConfirmCardSetupFailure?.send(
        typeof err === 'string' ? err : err.message
      )));
}
