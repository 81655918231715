/// <reference path="../../types/index.d.ts" />
import './init-elm-debugger';
import {initElmFramework} from './ElmFramework/Main';
import {handleSecureStorageIfSupported} from './Services/secure-storage';
import {handleAnalytics} from './Analytics';
import {defineSelectHorizontalElement} from './Ui/SlideSelectHorizontal';
import {defineSelectVerticalElement} from './Ui/SlideSelectVertical';
import {getPersisted, handlePersistence} from './Persistence';
import {graphqlSubscriptions} from "./Services/synchronised-async-services";
import {handleAuth} from "./auth";
import {handleQuickBooksIntegration} from "./Services/handle-quick-books-integration";

defineSelectHorizontalElement();
defineSelectVerticalElement();
initElmFramework({
  elSelector: 'elm-app-placeholder',
  initArgs: {
    configArgs: process.env.config,
    auth: null,
    persistedStaticData: null,
    persisted: getPersisted(),
    preFetchedStaticData: null
  },
  portHandlers: [
    ports => handleAuth(process.env.isHybrid, ports),
    handleAnalytics,
    handlePersistence,
    handleQuickBooksIntegration,
    graphqlSubscriptions.handleGraphQLSubscriptions,
  ],
  portHandlersOnReady: [handleSecureStorageIfSupported]
});



declare global {
  interface Window {
    GotaGuy: any;
    preFetchedCommonFile?: string;
    preFetchedCommonFilePromise: Promise<string>;
  }
}
