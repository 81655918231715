export {
  initCustomTap,
  CustomTapOptions
}

interface CustomTapOptions {
  touchBoundary?: number;
}

function initCustomTap(layer: HTMLElement, options?: CustomTapOptions): void {
  // let isDisabled: boolean = false;
  let targetElement: EventTarget | Element;
  let touchBoundary: number = options?.touchBoundary || 10;

  let touchStartX = 0;
  let touchStartY = 0;

  layer.addEventListener("click", onClick, false);
  layer.addEventListener("touchstart", onTouchStart, false);
  layer.addEventListener("touchmove", onTouchMove, false);
  layer.addEventListener("touchend", onTouchEnd, false);
  // layer.addEventListener("disableCustomTap", () => { isDisabled = true; });
  // layer.addEventListener("enableCustomTap", () => { isDisabled = false; });

  function onClick(event: MouseEvent): void {
    // if (isDisabled) {
    //   return;
    // } else {
      sendClick(event.target as HTMLElement, event);
    // }
  }

  function onTouchStart(event: TouchEvent): true {
    if (event.targetTouches.length > 1) return true;

    const touch = event.targetTouches[0];
    targetElement = event.target as HTMLElement;

    touchStartX = touch.pageX;
    touchStartY = touch.pageY;

    return true;
  }

  function onTouchMove(): boolean {
    return Boolean(touchStartX) && Boolean(touchStartY);
  }

  function onTouchEnd(event: TouchEvent): void {
    if (touchHasMoved(event)) {
      event.preventDefault();
    } else {
      const eventSucceeded = sendClick(targetElement, event);

      if (!eventSucceeded) {
        event.preventDefault();
      } else {
        return undefined;
      }
    }
  }

  function touchHasMoved(event: TouchEvent): boolean {
    const touch = event.changedTouches[0];

    const touchExceedsBoundaryOnX = Math.abs(touch.pageX - touchStartX) > touchBoundary;
    const touchExceedsBoundaryOnY = Math.abs(touch.pageY - touchStartY) > touchBoundary;

    return touchExceedsBoundaryOnX || touchExceedsBoundaryOnY;
  }

  function sendClick(targetElement: EventTarget | Element, event: TouchEvent | MouseEvent): boolean {
    const props = isTouchEvent(event) ? event.changedTouches[0] : event;
    let tapEvent = new MouseEvent("customTap", {
      bubbles: true,
      cancelable: true,
      screenX: props.screenX,
      screenY: props.screenY,
      clientX: props.clientX,
      clientY: props.clientY,
      button: 0
    });

    return targetElement.dispatchEvent(tapEvent);
  }
}

function isTouchEvent(e: Event): e is TouchEvent {
  return !!window.TouchEvent && e instanceof window.TouchEvent;
}
