import { loadStripe, Stripe, StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';

export {
  createCardElement,
  createToken,
  confirmCardSetup,
}

const stripe: Promise<Stripe | null> =
  stripeKeyPromise()
    .then(loadStripe);


const confirmCardSetup =
  (cfg: {cardEl: StripeCardElement, clientSecret: string}): Promise<void> =>
    stripe
      .then(stripeService =>
        stripeService?.confirmCardSetup(cfg.clientSecret, {
          payment_method: {
            card: cfg.cardEl,
          },
        })
          .then(r => r.error ? Promise.reject(r.error.message) : undefined));

const createCardElement =
  (cfg: {
    element: HTMLElement,
    classes: string,
    onChange: (e: StripeCardElementChangeEvent, card: StripeCardElement) => void
  }): Promise<StripeCardElement | null> =>
    stripe
      .then(stripeService => {
        const card =
          stripeService
            ?.elements()
            .create('card', {
              style: {
                base: {
                  lineHeight: '3',
                }
              },
              classes: { base: cfg.classes },
            }) ?? null;
        card?.on('change', v => cfg.onChange(v, card));
        card?.mount(cfg.element);
        return card;
      });

const createToken =
  (card: StripeCardElement): Promise<string | null> =>
    stripe
      .then(s =>
        s?.createToken(card)
          .then(r =>
            r.error
              ? Promise.reject(r.error)
              : (r.token?.id ?? null)
          ) ?? null);

function stripeKeyPromise(): Promise<string> {
  return new Promise(resolve => {
    if (process.env.config.stripeKey) {
      resolve(process.env.config.stripeKey);
    }
  }) ;
}
