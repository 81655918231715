import { PushPermissionStatus } from './Feature/PushMessages/web-push-notifications';
import { AppPorts, PushMessage } from '../elm-ports';
import { FacebookAuthSuccess, GoogleAuthSuccess } from './Feature/SocialAuth/SocialAuth';
import { NavLocation, RoutingCmdMap } from './Feature/Navigation/Routing';
import {
  GeolocationFailurePayload,
  GeolocationRequest,
  GeolocationSuccessPayload,
} from './Feature/Geolocation/Geolocation';
import { Browser } from './Feature/Browser/Browser';
import { Kinded, KnownKeysMatching } from './Lib/util';
import { StripeCardElement } from '@stripe/stripe-js';
import {PlacePredictionsOutput} from "./Feature/Places/PlacesPorts";

export {
  ElmFrameworkPorts,
  PortOut,
  PortIn,
  handlePortLoopback,
  PortsRecord,
  PortPayload,
  AllPorts,
  OutPortsKeys,
};

type AllPorts = AppPorts & ElmFrameworkPorts;
type OutPortsKeys = NonNullable<KnownKeysMatching<AllPorts, PortOut<any>>>


interface ElmFrameworkPorts extends PortsRecord {
  setLocationAccessAllowed: PortOut<boolean>
  socialWindowOpened: PortIn
  socialWindowClosed: PortIn
  receivePushStatus: PortIn<PushPermissionStatus>
  receivePushMsg: PortIn<PushMessage>
  setNotificationBadgeCount?: PortOut<number>
  enablePushNotifications?: PortOut
  lifeCycleEvent: PortIn<LifeCycleEvent>
  receiveGoogleAuth: PortIn<GoogleAuthSuccess>
  receiveFacebookAuth: PortIn<FacebookAuthSuccess>
  copyToClipboard?: PortOut<string>
  shareThroughHybridApi?: PortOut<ShareData>
  shareThroughWebApi?: PortOut<ShareData>
  shareViaFacebook?: PortOut<ShareData>
  shareViaTwitter?: PortOut<ShareData>
  shareViaLinkedIn?: PortOut<ShareData>
  shareViaGoogle?: PortOut<ShareData>
  userCouldNotProvidePaymentCard: PortIn
  userProvidesPaymentCard: PortIn<PaymentCardData>
  sendPaymentRequest?: PortOut<{ label: string, currency: string, amount: string }>
  exitApp?: PortOut
  sendDetailedEvent?: PortOut<{ event: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: number }>
  sendVirtualPageView?: PortOut<{ url: string, title: string }>
  receiveBeforeInstallPromptEvent: PortIn
  installPwa?: PortOut
  installPwaResult: PortIn<boolean>
  weAreOnline: PortIn
  scrollIntoView?: PortOut<ScrollIntoViewOptions & { elementSelector: string }>
  appUpdated: PortIn
  selectTextContent?: PortOut<string>
  detailedError?: PortOut<{ msg: string, extra: any }>
  generalError?: PortOut<string>
  setTitleAndDescription?: PortOut<{ title: string, description: string }>
  popState: PortIn<{ stateIndex: number, location: NavLocation }>
  routingCommands: PortOut<Kinded<RoutingCmdMap, 'kind'>[]>
  navToLoopback: PortOut<{ url: string, shouldReplace: boolean, shouldSilent: boolean }>
  navToLoopbackSub: PortIn<{ navigationLocation: NavLocation, shouldReplace: boolean, shouldSilent: boolean }>
  hardwareNavBack: PortIn
  hardwareNavForward: PortIn
  emitNotif?: PortOut<NotifRecord>
  listenNotif: PortIn<NotifRecord>
  setInputVal?: PortOut<{ id: string, val: string }>
  receiveLocationPermissions: PortIn<string>
  requestGeolocation?: PortOut<GeolocationRequest>
  receiveGeolocationSuccess: PortIn<GeolocationSuccessPayload>
  receiveGeolocationFailure: PortIn<GeolocationFailurePayload>
  callPhone?: PortOut<string>
  reloadApp?: PortOut<ShouldSkipCache>
  appLoaded: PortIn
  loadSocialLibraries?: PortOut
  googleSdkLoaded: PortIn
  facebookSdkLoaded: PortIn
  enoughInitialCpuIdle?: PortOut
  windowResize: PortIn<Browser>
  setCenterAndZoomPort?: PortOut<{ identifier: string, center: GeoPoint, zoom: number }>
  facebookLogin?: PortOut
  googleLogin?: PortOut
  authErrorLoopback: PortOut
  authErrorLoopbackSub: PortIn
  confirmStripeCardSetup: PortOut<{cardEl: StripeCardElement, clientSecret: string}>
  receiveStripeConfirmCardSetupSuccess: PortIn
  receiveStripeConfirmCardSetupFailure: PortIn<string>
  getPlacePredictions: PortOut<string>
  receivePlacePredictions: PortIn<PlacePredictionsOutput>
  focus: PortOut<string>
  select: PortOut<string>
}

type ShouldSkipCache = boolean

interface NotifRecord {
  kind: 'info' | 'error' | 'success'
  title: string
  bodies: string[]
}

type PortIn<T = null> = {
  send: (a: T) => void
}

type PortOut<T = null> = {
  subscribe: (cb: (a: T) => void) => void
}

interface PaymentCardData {
  cardNo: string
  expMonth: string
  expYear: string
  cv2: string
}

type LifeCycleEvent = 'Resume' | 'Pause';

interface ShareData {
  title: string,
  body: string,
  url: string
}

interface GeoPoint {
  lat: number
  lng: number
}

type PortsRecord = { [k: string]: PortIn<any> | PortOut<any> | undefined }

type PortPayload<Port extends PortOut<any>> =
  Parameters<Parameters<Port['subscribe']>[0]>[0]


const handlePortLoopback = <T>(config: { portOut?: PortOut<T>, portIn: PortIn<T> }): void => {
  config.portOut?.subscribe(val => config.portIn.send(val));
};

