import {googlePlaces} from "../../synchronised-async-services";
import {ElmFrameworkPorts} from "../../ElmFrameworkPorts";

export {
  PlacePrediction,
  PlacePredictionsOutput,
  handlePlacesPorts,
};

interface PlacePrediction {
  placeId: string;
  placeDescription: string;
}
interface PlacePredictionsOutput {
  input: string;
  placePredictions: Array<PlacePrediction> | null;
}

function handlePlacesPorts({ getPlacePredictions: getPlacePredictionsPort, receivePlacePredictions }: ElmFrameworkPorts): void {
  getPlacePredictionsPort.subscribe(async (input: string) => {
    try {
      const predictions = await googlePlaces.getPlacePredictions(input);
      const placePredictions = { input, placePredictions: transformToPlacePrediction(predictions) };
      receivePlacePredictions.send(placePredictions);
    } catch (err) {
      console.error("Some error occurred", err);
      receivePlacePredictions.send({ input, placePredictions: null });
    }
  });

  function transformToPlacePrediction( predictions: google.maps.places.AutocompletePrediction[] ): PlacePrediction[] {
    return predictions
      .filter((prediction) => Boolean(prediction.place_id))
      .map(({ place_id, description }) => ({
        placeId: place_id,
        placeDescription: description,
      })) as PlacePrediction[];
  }
}
