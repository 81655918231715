import {insertScriptElement, once} from "../../Lib/util";
import GoogleAuth = gapi.auth2.GoogleAuth;

export {
  toGoogleAuthSdk,
}

const toGoogleSdk: () => Promise<typeof gapi> =
  once(() =>
    insertScriptElement("https://apis.google.com/js/client:platform.js", true, true, 'google-jssdk')
      .then(() => gapi));

const toGoogleAuthSdk: (clientId: string) => Promise<GoogleAuth> =
  once(clientId =>
    toGoogleSdk()
      .then(sdk =>
        loadGoogleSubSdk(sdk, 'auth2', sdk => sdk.auth2.init({client_id: clientId}))));


function loadGoogleSubSdk<SubSdk>(googleSdk: typeof gapi, sdkDiscoveryName: string, toSdk: (googleSdk: typeof gapi) => SubSdk): Promise<SubSdk> {
  return new Promise((resolve, reject) => {
    googleSdk.load(sdkDiscoveryName, {
      callback: () => resolve(toSdk(googleSdk)),
      onerror: (err: any) => reject(err)
    });
  });
}











