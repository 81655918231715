import { ElmFrameworkPorts } from '../../ElmFrameworkPorts';
import { eventAssigner } from '../../Lib/util';

export {
  toLocation,
  handleNavigation,
  NavLocation,
  RoutingCmdMap,
};


function handleNavigation(appPorts: ElmFrameworkPorts): void {
  // handleAnchorClicks(appPorts);
  window.addEventListener('popstate', e =>
    appPorts.popState.send({
      stateIndex: e.state.stateIndex,
      location: toLocation(),
    }));

  appPorts.routingCommands.subscribe(cmds => cmds.forEach(eventAssigner(routingHandlers, 'kind')));

  appPorts.navToLoopback?.subscribe(cmd =>
    appPorts.navToLoopbackSub.send({
      navigationLocation: toLocation(cmd.url),
      shouldReplace: cmd.shouldReplace,
      shouldSilent: cmd.shouldSilent,
    }));


  appPorts.reloadApp?.subscribe(skipCache => document.location.reload(skipCache));
}

const routingHandlers: { [key in keyof RoutingCmdMap]: (e: RoutingCmdMap[key]) => void } = {
  go: ({steps}) => history.go(steps),
  pushState: e => history.pushState({stateIndex: e.stateIndex}, e.title, e.url),
  replaceState: e => history.replaceState({stateIndex: e.stateIndex}, e.title, e.url),
};


interface RoutingCmdMap {
  pushState: { stateIndex: number, title: string, url: string }
  replaceState: { stateIndex: number, title: string, url: string }
  go: { steps: number }
}

// function handleAnchorClicks(appPorts: ElmFrameworkPorts): void {
//   document.body.addEventListener('click', e => {
//     if (shouldIgnore(e)) return true;
//     const anchor = toAnchor(e);
//     if (anchor && isInboundHref(anchor)) {
//       handleEvent(e, anchor, appPorts);
//     } else {
//       return true;
//     }
//   });
//
//   function handleEvent(e: MouseEvent, anchor: HTMLAnchorElement, appPorts: ElmFrameworkPorts): void {
//     e.preventDefault();
//     history.pushState({}, '', anchor.getAttribute('href'));
//     appPorts.receiveNewLocation.send({location: toLocation(), shouldReplace: false});
//   }
//
//
//   function toAnchor(e: MouseEvent): HTMLAnchorElement | undefined {
//     for (let n = <HTMLElement>e.target; n.parentNode; n = <HTMLElement>n.parentNode) {
//       if (n.nodeName === 'A') {
//         return <HTMLAnchorElement>n;
//       }
//     }
//   }
//
//   function isInboundHref(el: HTMLAnchorElement): boolean {
//     return location.hostname === el.hostname;
//   }
//
//
//   function shouldIgnore(e: MouseEvent): boolean {
//     return e.altKey || e.ctrlKey || e.metaKey || e.shiftKey || e.defaultPrevented;
//   }
// }


const toLocation = (pathname?: string): NavLocation => ({
  href: document.location.href,
  host: document.location.host,
  hostname: document.location.hostname,
  protocol: document.location.protocol,
  origin: document.location.origin,
  port_: document.location.port,
  pathname: pathname || document.location.pathname,
  search: pathname ? '' : document.location.search,
  hash: document.location.hash,
});

interface NavLocation {
  href: string
  host: string
  hostname: string
  protocol: string
  origin: string
  port_: string
  pathname: string
  search: string
  hash: string
}