import { AppPorts, QuickBooksIntegrationData } from "../elm-ports";
import { openPopUpInCenterOfWindow } from "../ElmFramework/helpers";

export {
  handleQuickBooksIntegration,
}

function handleQuickBooksIntegration({
  openQuickBooksIntegration,
  receiveQuickBooksIntegrationResult
}: AppPorts): void {
  const trustedOrigin = 'https://oauth.puffin.bernoullifinance.com';
  const popupUrl = `${trustedOrigin}/oauthInit/intuit`;
  openQuickBooksIntegration.subscribe(
    (integrationData: QuickBooksIntegrationData) => {
      const quickbookswindow = openPopUpInCenterOfWindow({
        url: popupUrl,
        title: 'quickbookswindow',
        popUpWidth: 700,
        popUpHeight: 700
      });
      if (null !== quickbookswindow) {
        let requestResultInterval: ReturnType<typeof setInterval> | null = null;
        let noResponseTimer: ReturnType<typeof setTimeout> | null = null;
        window.addEventListener(
          'message',
          function quickbooksMsgHandler(event: MessageEvent) {
            const data = event.data;
            if (event.origin !== trustedOrigin) {
              return;
            }
            console.log('Received event data from quickbookswindow: ', data);
            switch (data?.authPopup?.state) {
              case 'needsinit': {
                quickbookswindow.postMessage(
                  {
                    token: integrationData.token,
                    companyId: integrationData.companyId,
                    divisionId: integrationData.divisionId
                  },
                  trustedOrigin
                );
                requestResultInterval = setInterval(() => {
                  quickbookswindow.postMessage(
                    { request: 'result' },
                    trustedOrigin
                  );
                  console.log('posted result message to quickbooks window');
                  if (quickbookswindow.closed) {
                    console.log('pop up closed');
                    if (null !== requestResultInterval) {
                      clearInterval(requestResultInterval);
                    }
                    if (null !== noResponseTimer) {
                      clearTimeout(noResponseTimer);
                    }
                    window.removeEventListener('message', quickbooksMsgHandler);
                    receiveQuickBooksIntegrationResult.send({
                      type: 'Error',
                      errorMessage: 'Pop up window closed unexpectedly',
                      errorType: 'PopUpClosed'
                    });
                  }
                }, 5 * 1000);
                noResponseTimer = setTimeout(
                  () => {
                    console.error(
                      'no response from quickbooks window after 10 mins'
                    );
                    if (null !== requestResultInterval) {
                      clearInterval(requestResultInterval);
                    }
                    window.removeEventListener('message', quickbooksMsgHandler);
                    quickbookswindow.close();
                    receiveQuickBooksIntegrationResult.send({
                      type: 'Error',
                      errorMessage:
                        data.authPopup.message ??
                        'Popup failed to respond, timed out after 10secs',
                      errorType: data.authPopup.messageId ?? 'Popup failure'
                    });
                  },
                  10 * 60 * 1000 // error out after 10 mins of waiting.
                );
                return;
              }
              case 'error': {
                if (null !== requestResultInterval) {
                  clearInterval(requestResultInterval);
                }
                if (null !== noResponseTimer) {
                  clearTimeout(noResponseTimer);
                }
                window.removeEventListener('message', quickbooksMsgHandler);
                quickbookswindow.close();
                receiveQuickBooksIntegrationResult.send({
                  type: 'Error',
                  errorMessage:
                    data.authPopup.message ?? 'Received unknown error',
                  errorType: data.authPopup.messageId ?? 'UnknownError'
                });
                return;
              }
              case 'success': {
                if (null !== requestResultInterval) {
                  clearInterval(requestResultInterval);
                }
                if (null !== noResponseTimer) {
                  clearTimeout(noResponseTimer);
                }
                quickbookswindow.close();
                window.removeEventListener('message', quickbooksMsgHandler);
                receiveQuickBooksIntegrationResult.send({ type: 'Success', companyId: integrationData.companyId });
                return;
              }
              default: {
                if (null !== requestResultInterval) {
                  clearInterval(requestResultInterval);
                }
                if (null !== noResponseTimer) {
                  clearTimeout(noResponseTimer);
                }
                console.error(
                  'Quickbooks integration: Unexpected event:',
                  event
                );
                quickbookswindow.close();
                window.removeEventListener('message', quickbooksMsgHandler);
                receiveQuickBooksIntegrationResult.send({
                  type: 'Error',
                  errorMessage:
                    data.authPopup.message ?? 'Received unexpected message',
                  errorType: data.authPopup.messageId ?? 'UnknownError'
                });
              }
            }
          }
        );
      }
    }
  );
}