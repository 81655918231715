import {insertScriptElement, once} from "../../Lib/util";


export {
  toFacebookSdk,
}


const toFacebookSdk: (fbId: string) => Promise<fb.FacebookStatic> =
  once(facebookId => {
    insertScriptElement('https://connect.facebook.net/en_US/sdk.js', true, true, 'facebook-jssdk');
    return promisifySdk(facebookId);
  });


function promisifySdk(facebookId: string): Promise<fb.FacebookStatic> {
  return new Promise(resolve => {
    // @ts-ignore
    window['fbAsyncInit'] = () => {
      FB.init({
        appId: facebookId,
        cookie: true,
        xfbml: false,  // parse social plugins on this page
        version: 'v3.2'
      });
      resolve(FB);
    };
  });
}
