import { property } from 'lit-element';
import { ElmLitElement } from '../ElmLitElement';
import { createCardElement } from './stripe-service';

export {
  defineStripeInputElement,
};

const defineStripeInputElement = (): void =>
  customElements.define('stripe-input', StripeInputElement);



class StripeInputElement extends ElmLitElement {
  @property({type: String, attribute: 'stripe-classes'}) stripeClasses: string;
  
  
  connectedCallback(): void {
    createCardElement({
      element: this,
      classes: this.stripeClasses,
      onChange: (e, card) =>
        e.error
          ? this.fire('onInputError', e.error.message)
          : this.fire('onValidInput', card),
    });
  }
  
  disconnectedCallback(): void {  }
}

