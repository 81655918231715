import {os} from 'platform';

export {
  log,
  logErr,
  PROMISE_TIMED_OUT,
  timeoutPromise,
  all,
  identity,
  browserLocale,
  randomString,
  doBeforeReject,
  once,
  MaybeBool,
  observeDom,
  onKiss,
  onTap,
  fireEvent,
  onImgLoad,
  onIdle,
  onLoad,
  delay,
  toTransitionDuration,
  apply,
  KnownKeysMatching,
  KeysMatching,
  KnownKeys,
  eventAssigner,
  Kinded,
  bufferCbUntil,
  isSafari,
  throwMsg,
  insertScriptElement,
  insertMetaElement,
  objEntries,
  getElementIndex,
  captureEvent,
  captureFirstChildEvent,
}

function log(msg: string, ...rest: any[]): <val>(a: val) => val {
  return a => {
    console.log(msg, ...rest, a);
    return a;
  };
}

function logErr<val>(msg: string, ...rest: any[]): (a: val) => val {
  return a => {
    console.error(msg, ...rest, a);
    return a;
  };
}


const PROMISE_TIMED_OUT = 'PROMISE_TIMED_OUT';

function timeoutPromise<T>(time: number, promise: Promise<T>): Promise<T> {
  return Promise.race([
    timeoutRejectionPromise(time),
    promise
  ]);

  function timeoutRejectionPromise(time: number): Promise<never> {
    return new Promise((_, reject) => setTimeout(reject.bind(null, PROMISE_TIMED_OUT), time));
  }
}

function randomString(length: number): string {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
function all<T1, Param>(a1: Res<Param, T1>): (p: Param) => Promise<[T1]>;
function all<T1, T2, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>): (p: Param) => Promise<[T1, T2]>;
function all<T1, T2, T3, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>): (p: Param) => Promise<[T1, T2, T3]>;
function all<T1, T2, T3, T4, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>, a4: Res<Param, T4>): (p: Param) => Promise<[T1, T2, T3, T4]>;
function all<T1, T2, T3, T4, T5, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>, a4: Res<Param, T4>, a5: Res<Param, T5>): (p: Param) => Promise<[T1, T2, T3, T4, T5]>;
function all<T1, T2, T3, T4, T5, T6, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>, a4: Res<Param, T4>, a5: Res<Param, T5>, a6: Res<Param, T6>): (p: Param) => Promise<[T1, T2, T3, T4, T5, T6]>;
function all<T1, T2, T3, T4, T5, T6, T7, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>, a4: Res<Param, T4>, a5: Res<Param, T5>, a6: Res<Param, T6>, a7: Res<Param, T7>): (p: Param) => Promise<[T1, T2, T3, T4, T5, T6, T7]>;
function all<T1, T2, T3, T4, T5, T6, T7, T8, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>, a4: Res<Param, T4>, a5: Res<Param, T5>, a6: Res<Param, T6>, a7: Res<Param, T7>, a8: Res<Param, T8>): (p: Param) => Promise<[T1, T2, T3, T4, T5, T6, T7, T8]>;
function all<T1, T2, T3, T4, T5, T6, T7, T8, T9, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>, a4: Res<Param, T4>, a5: Res<Param, T5>, a6: Res<Param, T6>, a7: Res<Param, T7>, a8: Res<Param, T8>, a9: Res<Param, T9>): (p: Param) => Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9]>;
function all<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, Param>(a1: Res<Param, T1>, a2: Res<Param, T2>, a3: Res<Param, T3>, a4: Res<Param, T4>, a5: Res<Param, T5>, a6: Res<Param, T6>, a7: Res<Param, T7>, a8: Res<Param, T8>, a9: Res<Param, T9>, a10: Res<Param, T10>): (p: Param) => Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9, T10]>;
function all<Param>(...values: Res<Param, any>[]): (p: Param) => Promise<any[]>;
function all<Param>(...values: Res<Param, any>[]): (p: Param) => Promise<any[]> {
  return param => Promise.all(values.map(obj => obj(param)));
}

type Res<I, O> = (i: I) => (O | Promise<O>)


// type Identity<T> = (a: T) => T

function identity<T>(a: T): T {
  return a;
}


function browserLocale(): string {
  let lang;

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    lang = navigator.languages[0];
  } else if ((<any>navigator).userLanguage) {
    // IE only
    lang = (<any>navigator).userLanguage;
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigator.language;
  }

  return lang;
}

function addDomElementAsync(elType: 'script' | 'meta', attrs: { [key: string]: string }, id?: string, callback?: () => void): void {
  let js: any, fjs: any = document.getElementsByTagName(elType)[0];
  if (id && document.getElementById(id)) { return; }
  js = document.createElement(elType);
  if (!!id) {
    js.id = id;
  }
  Object.keys(attrs).forEach(function (attrName) {
    js[attrName] = attrs[attrName];
  });

  if (!!callback) {
    if (js.readyState) {  //IE
      js.onreadystatechange = function () {
        if (js.readyState === "loaded" || js.readyState === "complete") {
          js.onreadystatechange = null;
          callback();
        }
      };
    } else {  //Others
      js.onload = function () {
        callback();
      };
    }
  }

  fjs.parentNode.insertBefore(js, fjs);
}

function insertScriptElement(src: string, async: boolean, defer: boolean, id?: string): Promise<void> {
  const attrs: any = {src};
  if (async) {
    attrs['async'] = "";
  }
  if (defer) {
    attrs['defer'] = "";
  }
  return new Promise(resolve => addDomElementAsync('script', attrs, id, resolve));
}

function insertMetaElement(name: string, content: string): void {
  addDomElementAsync('meta', {name, content});
}



export function doWait<V>(...doers: Doer<V>[]): (v: V) => Promise<V> {
  return v =>
    Promise.all(doers.map(apply(v)))
      .then(always(v));
}

type Doer<V> = (val: V) => any

type Identity<T> = (a: T) => T

export function justDo<V>(...doers: Doer<V>[]): Identity<V> {
  return val => {
    doers.forEach(apply(val));
    return val;
  };
}


function doBeforeReject<V>(...doers: Doer<V>[]): (v: V) => Promise<never> {
  return v =>
    Promise.all(doers.map(apply(v)))
      .then(always(Promise.reject(v)));
}


const apply =
  <I>(value: I) =>
    <O>(fn: (i: I) => O): O =>
      fn(value);

export function always<V>(v: V): () => V {
  return (): V => v;
}

export function alwaysVoid(): void {
}


export const synchroniseAsyncService =
  <T extends { [k: string]: (...args: any[]) => any },
    ApiKeys extends keyof T>
  ({service, api}: { service: Promise<T>, api: ApiKeys[] }):
    { [k in ApiKeys]: PromisifiedFn<T[k]> } =>
    api.reduce((memo, name) => {
      memo[name] = digInPromisedService(service, name);
      return memo;
    }, {} as { [k in ApiKeys]: PromisifiedFn<T[k]> });


const digInPromisedService =
  <K extends keyof O, O extends { [k: string]: any }>
  (service: Promise<O>, fnName: K) =>
    (...args: Parameters<O[K]>): Promisified<ReturnType<O[K]>> =>
      <Promisified<ReturnType<O[K]>>>Promise.all([service, fnName, args])
        .then(([service, fnName, args]) => <ReturnType<O[K]>>service[fnName](...args));


type Promisified<T> =
  T extends Promise<any> ? T : Promise<T>;

type PromisifiedFn<Fn extends (...args: any[]) => any> =
  (...args: Parameters<Fn>) => Promisified<ReturnType<Fn>>

function objEntries(obj: any): [string, any][] {
  let ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i); // preallocate the Array
  while (i--)
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  return resArray;
}


//-----------------------------------------------------------------------------------

interface expoBackoffArgs<w> {
  work: () => Promise<w>
  shouldRetry: (err: any) => boolean
}

export function expoBackoff<w>({work, shouldRetry}: expoBackoffArgs<w>): Promise<w> {
  return run(0);

  function run(delayTime: number): Promise<w> {
    return delay(delayTime)
      .then(work)
      .catch(err =>
        shouldRetry(err) ?
          run(createDelay(delayTime)) :
          Promise.reject(err));
  }

  function createDelay(previousDelay: number): number {
    const newDelay = previousDelay < 1000 ? 1000 : previousDelay;
    const rand = randomIntFromInterval(0.5 * newDelay, newDelay + 3000);
    console.log('random delay', rand);
    return rand;
  }


  function delay(milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
}

//-----------------------------------------------------------------------------------


const randomIntFromInterval = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);


export type Id = string
export type EpochTime = number


export function debounce(func: () => any, wait: number, immediate?: boolean): () => void
export function debounce<T1>(func: (a: T1) => any, wait: number, immediate?: boolean): (a: T1) => void
export function debounce<T1, T2>(func: (a: T1, b: T2) => any, wait: number, immediate?: boolean): (a: T1, b: T2) => void
export function debounce<T1, T2, T3>(func: (a: T1, b: T2, c: T3) => any, wait: number, immediate?: boolean): (a: T1, b: T2, c: T3) => void
export function debounce<T1, T2, T3, T4>(func: (a1: T1, a2: T2, a3: T3, a4: T4) => any, wait: number, immediate?: boolean): (a1: T1, a2: T2, a3: T3, a4: T4) => void
export function debounce<T1, T2, T3, T4, T5>(func: (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5) => any, wait: number, immediate?: boolean): (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5) => void
export function debounce<T1, T2, T3, T4, T5, T6>(func: (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5, a6: T6) => any, wait: number, immediate?: boolean): (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5, a6: T6) => void
export function debounce<T1, T2, T3, T4, T5, T6, T7>(func: (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5, a6: T6, a7: T7) => any, wait: number, immediate?: boolean): (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5, a6: T6, a7: T7) => void
export function debounce<T1, T2, T3, T4, T5, T6, T7, T8>(func: (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5, a6: T6, a7: T7, a8: T8) => any, wait: number, immediate?: boolean): (a1: T1, a2: T2, a3: T3, a4: T4, a5: T5, a6: T6, a7: T7, a8: T8) => void {
  let timeout: any;
  return function () {
    // @ts-ignore
    let context: any = this, args: any = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}


function once<T extends Function>(fn: T, context?: any): T {
  let theFn: T | null = fn;
  let result: any;
  return function () {
    if (theFn) {
      // @ts-ignore
      result = theFn.apply(context || this, arguments);
      theFn = null;
    }
    return result;
  } as Function as T;
}

type MaybeBool = true | false | 'UNKNOWN';

const observeDom = (el: Node, cb: MutationCallback, config?: MutationObserverInit): MutationObserver => {
  const conf = config || {childList: true};
  const observer = new MutationObserver(cb.bind(el));
  observer.observe(el, conf);
  return observer;
};

const onKiss = (el: any, cb: any, shouldDisableEvent: any) => {
  const theCb = cb;
  if (('ontouchstart' in document.documentElement) || ('ontouchstart' in window) || ('DocumentTouch' in window)) {
    return onTap(el, theCb, shouldDisableEvent);
  } else {
    el.addEventListener('click', theCb);
    return () => el.removeEventListener('click', theCb);
  }
};

const onTap = (el: any, cb: any, shouldDisableEvent: any) => {
  let moved = false;
  let startX: any = null;
  let startY: any = null;
  el.addEventListener('touchstart', (e: any) => {
    moved = false;
    startX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    startY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;
    if (shouldDisableEvent) {
      disableEvent(e);
    }
  });
  el.addEventListener('touchmove', (e: any) => {
    const x = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
    const y = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;
    if (Math.abs(x - startX) > 10 || Math.abs(y - startY) > 10) {
      moved = true;
    }
    if (shouldDisableEvent) {
      disableEvent(e);
    }
  });
  el.addEventListener('touchcancel', reset);
  el.addEventListener('touchend', (e: any) => {
    if (!moved) {
      cb()
    }
    reset();
    if (shouldDisableEvent) {
      disableEvent(e);
    }
  });
  return function (el: any) {
    // @ts-ignore
    el.removeEventListener('touchstart', this, false);
    // @ts-ignore
    el.removeEventListener('touchmove', this, false);
    // @ts-ignore
    el.removeEventListener('touchend', this, false);
    // @ts-ignore
    el.removeEventListener('touchcancel', this, false);
  }.bind(null, el);

  function reset() {
    moved = false;
    startX = null;
    startY = null;
  }
};

const disableEvent = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};

const fireEvent = (node: any, evtName: any) => {
  if (CustomEvent) {
    const evt = new CustomEvent(evtName, {bubbles: true, cancelable: true});
    node.dispatchEvent(evt);
  } else if (document.createEvent) {
    const evt = document.createEvent('MouseEvents');
    evt.initEvent(evtName, true, false);
    node.dispatchEvent(evt);
  } else if ((<any>document).createEventObject) {
    node.fireEvent(evtName);
  } else {
    throw new Error('No way to trigger markerTap event');
  }
};


const onImgLoad = (imgUrl: string, cb: Function) => {
  let imgEl = new Image();
  imgEl.onload = cb.bind(null, imgUrl, imgEl);
  imgEl.src = imgUrl;
};


const onIdle = (): Promise<void> => {
  const idleFn = ((<any>window)['requestIdleCallback']) || requestAnimationFrame || setTimeout;
  return new Promise(resolve => idleFn(resolve));
};

const onLoad: Promise<void> = new Promise(resolve => {
  window.addEventListener('load', () => resolve());
});

const delay =
  (milliseconds: number) =>
    <T>(input: T): Promise<T> =>
      new Promise(resolve => {
        setTimeout(() => resolve(input), milliseconds);
      });



function toTransitionDuration(el: HTMLElement): number {
  const duration = window.getComputedStyle(el).transitionDuration;
  if (duration.length === 0) { return 0 }
  const unit = duration.slice(-2).toLowerCase();
  const multiplier = unit === 'ms' ? 1 : 1000;
  const durationClean = /([0-9]|\.)+/.exec(duration)?.[0] || throwMsg('toTransitionDuration: no duration found');
  return parseFloat(durationClean) * multiplier;
}


// https://stackoverflow.com/a/58980331/592641
type KnownKeysMatching<T, V> = KeysMatching<Pick<T, KnownKeys<T>>, V>
type KeysMatching<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T];
type KnownKeys<T> = Extract<{
  [K in keyof T]: string extends K ? never : number extends K ? never : K
} extends { [_ in keyof T]: infer U } ? U : never, keyof T>;


const eventAssigner = <
  M extends Record<keyof M, (e: any) => any>,
  D extends keyof any
  >(
  handlers: M,
  discriminant: D
) => <K extends keyof M>(
  event: Record<D, K> & (Parameters<M[K]>[0])
): ReturnType<M[K]> => handlers[event[discriminant]](event);


type Kinded<EventMap extends object, Discriminant extends string> = { [key in keyof EventMap]: Record<Discriminant, key> & EventMap[key] }[keyof EventMap]


function bufferCbUntil<T>(resumeOn: Promise<void>, handler: (e: T) => void) {
  let resumed = false;
  const buffer: T[] = [];
  resumeOn.then(() => {
    resumed = true;
    buffer.forEach(handler);
  });
  return function bufferer(event: T): void {
    if (resumed) {
      handler(event);
    } else {
      buffer.push(event);
    }
  };
}

// https://stackoverflow.com/a/23522755/592641
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || os?.family === 'iOS';

function throwMsg(msg: string): never {
  throw new Error(msg);
}

function getElementIndex(node: HTMLElement, nodeArray?: HTMLElement[]): number {
  const arrayLike = nodeArray || node.parentElement?.children || [];
  return Array.prototype.indexOf.call(arrayLike, node);
}

// https://stackoverflow.com/a/45705202/592641
function captureEvent(eventName: string, root: HTMLElement, selector: string, handler: (e: Event) => void): void {
  root.addEventListener(eventName, (e: Event) => {
    if (e.target instanceof HTMLElement && e.target.matches(selector)) {
      handler(e);
    }
  });
}
function captureFirstChildEvent<K extends keyof GlobalEventHandlersEventMap>(eventName: K, root: HTMLElement, handler: (e: GlobalEventHandlersEventMap[K], immediateChild: HTMLElement) => void): void {
  root.addEventListener(eventName, e => {
    if (e.target !== root && e.target instanceof HTMLElement) {
      handler(e, toImmediateChild(e.target))
    }
  });

  function toImmediateChild(child: HTMLElement): HTMLElement {
    return child.parentElement === root
      ? child :
      toImmediateChild(child.parentElement as HTMLElement);
  }
}
