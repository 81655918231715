import { AppPorts, UserAuthSession } from "./elm-ports";
import { getItem } from "./ElmFramework/Lib/LocalStorage";
import * as LocalStorage from './ElmFramework/Lib/LocalStorage';

const AUTH = 'AUTH';

export {
  handlePersistence,
  getPersisted,
  clearPersistence,
}

function handlePersistence({ newUserSession, newUserAuthenticated }: AppPorts): void {
  newUserSession.subscribe(
    (auth: UserAuthSession | null): void =>
      null === auth ? LocalStorage.removeItem(AUTH) : LocalStorage.setItem(AUTH, auth)
  );
  newUserAuthenticated.subscribe((auth: UserAuthSession): void => LocalStorage.setItem(AUTH, auth));
}


function clearPersistence(): void {
  localStorage.clear();
}

function getPersisted() {
  return {
    auth: getItem(AUTH),
  };
}
