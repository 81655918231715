import {ElmFrameworkPorts} from '../../ElmFrameworkPorts';
import * as LocalStorage from '../../Lib/LocalStorage';
import {bufferCbUntil} from '../../Lib/util';
import {whenReady} from '../../helpers';

const LOCATION_ACCESS_ALLOWED = <const>'LOCATION_ACCESS_ALLOWED';

export {
  handleGeolocation,
  getPersistedGeoLocationAccessStatus,
  GeolocationRequest,
  GeolocationSuccessPayload,
  GeolocationFailurePayload,
};

function getPersistedGeoLocationAccessStatus(): boolean | undefined {
  return LocalStorage.getItem(LOCATION_ACCESS_ALLOWED);
}

function handleGeolocation(isHybrid: boolean, appPorts: ElmFrameworkPorts): void {
  whenReady.then(() => checkLocationAvailabilityForHybrid(isHybrid, appPorts));
  appPorts.setLocationAccessAllowed.subscribe(persistGeoLocationAccessStatus);
  appPorts.requestGeolocation?.subscribe(bufferCbUntil(whenReady, geolocationReqHandler(appPorts)));
}

function geolocationReqHandler(appPorts: ElmFrameworkPorts) {
  return (req: GeolocationRequest): void => {
    navigator.geolocation.getCurrentPosition(
      (position) => appPorts.receiveGeolocationSuccess.send({
        isExplicitlyRequested: req.explicitRequest,
        geolocationSuccess: positionToLocation(position),
      }),
      (err) => appPorts.receiveGeolocationFailure.send({
        isExplicitlyRequested: req.explicitRequest,
        geolocationError: geoErrToFailure(err),
      }),
      toOptions(req),
    );
  };
}

function persistGeoLocationAccessStatus(status: boolean | undefined): void {
  LocalStorage.setItem(LOCATION_ACCESS_ALLOWED, status);
}

function checkLocationAvailabilityForHybrid(isHybrid: boolean, appPorts: ElmFrameworkPorts): void {
  if (isHybrid) {
    getLocationPermissionsStatusForHybrid(appPorts);
  }
}

const getLocationPermissionsStatusForHybrid = (appPorts: ElmFrameworkPorts): void =>
  (<any>window.cordova.plugins).diagnostic.getLocationAuthorizationStatus(
    (status: any) => appPorts.receiveLocationPermissions.send(fromDiagnosticStatus(status)),
    handleGetLocationPermissionsStatusErr,
  );

function fromDiagnosticStatus(status: any) {
  switch (status) {
    case (<any>window.cordova.plugins).diagnostic.permissionStatus.GRANTED:
      return 'GRANTED';
    case (<any>window.cordova.plugins).diagnostic.permissionStatus.NOT_REQUESTED:
      return 'NOT_REQUESTED';
    case (<any>window.cordova.plugins).diagnostic.permissionStatus.DENIED_ONCE:
      return 'DENIED';
    case (<any>window.cordova.plugins).diagnostic.permissionStatus.DENIED_ALWAYS:
      return 'DENIED_ALWAYS';
    case (<any>window.cordova.plugins).diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
      return 'GRANTED_WHEN_IN_USE';
    case (<any>window.cordova.plugins).diagnostic.permissionStatus.RESTRICTED:
      return 'RESTRICTED';
    default:
      return status;
  }
}

function handleGetLocationPermissionsStatusErr(error: string) {
  throw new Error('getLocationAuthorizationStatusFromCordova: ' + error);
}


declare global {
  interface Window {
    BackgroundGeolocation: any
  }
}

function toOptions(options: GeolocationRequest): PositionOptions {
  return {
    enableHighAccuracy: options.enableHighAccuracy,
    timeout: options.timeout || Infinity,
    maximumAge: options.maximumAge || 0,
  };
}

function positionToLocation(rawPosition: GeolocationPosition): Geolocation {
  const coords = rawPosition.coords;

  // var rawAltitude = coords.altitude;
  // var rawAccuracy = coords.altitudeAccuracy;
  // var altitude =
  //   (rawAltitude === null || rawAccuracy === null)
  //     ? _elm_lang$core$Maybe$Nothing
  //     : _elm_lang$core$Maybe$Just({value: rawAltitude, accuracy: rawAccuracy});

  // var heading = coords.heading;
  // var speed = coords.speed;
  // var movement =
  //   (heading === null || speed === null)
  //     ? _elm_lang$core$Maybe$Nothing
  //     : _elm_lang$core$Maybe$Just(
  //     speed === 0
  //       ? {ctor: 'Static'}
  //       : {ctor: 'Moving', _0: {speed: speed, degreesFromNorth: heading}}
  //     );

  return {
    latitude: coords.latitude,
    longitude: coords.longitude,
    accuracy: coords.accuracy,
    // altitude: altitude,
    // movement: movement,
    // timestamp: rawPosition.timestamp
  };
}

function geoErrToFailure(err: GeolocationPositionError): GeolocationError {
  const errorTypes: GeoErrorKind[] = ['PermissionDenied', 'LocationUnavailable', 'Timeout'];
  return {
    kind: errorTypes[err.code - 1],
    details: err.message,
  };
}


interface GeolocationRequest {
  explicitRequest: boolean,
  enableHighAccuracy: boolean,
  timeout: number | undefined,
  maximumAge: number | undefined
}


interface GeolocationSuccessPayload {
  isExplicitlyRequested: boolean
  geolocationSuccess: Geolocation
}

interface Geolocation {
  latitude: number
  longitude: number
  accuracy: number
  // altitude?: Altitude
  // movement?: Movement
  // timestamp: number
}

// interface Altitude {
//   value: number,
//   accuracy: number
// }
//
// type Movement
//   = { kind: 'Static' }
//   | { kind: 'Moving', speed: number, degreesFromNorth: number }


interface GeolocationFailurePayload {
  isExplicitlyRequested: boolean
  geolocationError: GeolocationError
}

interface GeolocationError {
  kind: GeoErrorKind,
  details: string,
}

type GeoErrorKind = 'PermissionDenied' | 'LocationUnavailable' | 'Timeout';