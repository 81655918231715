import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';

export {
  captureBreadcrumb,
  captureMessage,
  captureException,
  setUserContext,
  unsetUserContext,
  context,
  ErrReporterUserContext,
}

// const sentry = process.env.isHybrid ? initSentryCordova(process.env.isActiveDevelopment) : initRaven(process.env.isActiveDevelopment);
const sentry = initWeb(process.env.isActiveDevelopment);
// const sentry = {
//   addBreadcrumb: (bla: any) => undefined,
//   captureMessage: window.onerror || (() => undefined),
//   captureException: window.onerror || (() => undefined),
//   configureScope: (cb: Function) => cb(),
//   setUser: (bla: any) => console.log(initWeb),
// };

const captureBreadcrumb = sentry.addBreadcrumb.bind(sentry);
const captureMessage = sentry.captureMessage.bind(sentry);
const captureException = sentry.captureException.bind(sentry);
// @ts-ignore
window['errorReporterCaptureBreadcrumb'] = captureBreadcrumb; // used by elm > Util

const context =
  process.env.isHybrid ?
    (fn: Function) => fn() : //todo: figure out context for hybrid
    sentry.configureScope.bind(sentry);

type ErrReporterUserContext = { id: string, username?: string, email?: string }
const setUserContext = (user: ErrReporterUserContext): void =>
  sentry.setUser(user);

const unsetUserContext = (): void =>
  sentry.setUser(null);


function initWeb(isDevelopment: boolean): typeof Sentry {
  Sentry.init({
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    dsn: process.env.config.sentryId,
    ignoreErrors: isDevelopment ? [/.*/g] : [],
    environment: process.env.audience + '-' + process.env.platform,
    maxBreadcrumbs: 20,
    release: process.env.commit,
    // serverName: os,
    // maxMessageLength: 1000,
  });
  return Sentry;
}


// function initSentryCordova(isDevelopment: boolean) {
//   const Sentry = require('sentry-cordova');
//   Sentry.init({
//     ignoreErrors: isDevelopment ? [/.*/g] : [],
//     dsn: process.env.config.sentryId,
//     environment: process.env.audience + '-' + process.env.platform,
//     release: process.env.commit,
//     maxBreadcrumbs: 20,
//     // serverName: os,
//     stacktrace: true,
//     // maxMessageLength: 1000,
//   });
//   return Sentry;
// }
